import React from "react";
import { Link } from "gatsby";

import * as styles from "./Footer.module.scss";
import Image from "../../components/Image";
import { parseMardown } from "../../utils/textTransformations";
import PolitykaPrywatnosci from "../../assets/pdfs/polityka-prywatnosci-edugo-v.1.1.pdf";

const FooterItem = ({ node: { slug, title, notVisibleInCourses } }) => {
  if (notVisibleInCourses) {
    return null;
  }

  return (
    <Link className={styles.pageLink} to={`/kurs/${slug}`} key={slug}>
      {title}
    </Link>
  );
};

const Footer = ({
  data: {
    strapiFooter: {
      contactNumber,
      contactNumberText,
      contactDetails,
      email,
      info,
      logo,
    },
    allStrapiLanding,
    strapiGlobal: { menuIconLinks },
  },
}) => {
  const shouldShowMenuIconLinks = !!(
    menuIconLinks &&
    !!menuIconLinks.length &&
    menuIconLinks[0].link
  );

  return (
    <div className={styles.self}>
      <div className={styles.inner}>
        <div className={styles.columnsWrapper}>
          <Link className={styles.desktopLogo} to="/">
            <Image className={styles.logo} alt="eduGO" image={logo} />
          </Link>

          <div id="kontakt" className={styles.contactSection}>
            <Link className={styles.mobileLogo} to="/">
              <Image className={styles.logo} alt="eduGO" image={logo} />
            </Link>
            <div className={styles.contactColumn}>
              <a className={styles.phoneNumber} href={`tel:${contactNumber}`}>
                {contactNumberText}
              </a>
              <a className={styles.email} href={`mailto:${email}`}>
                {email}
              </a>
              <div className={styles.contactDetails}>{contactDetails}</div>
              <a
                className={styles.privacyPolicy}
                href={PolitykaPrywatnosci}
                target="_blank"
              >
                Polityka prywatności
              </a>
            </div>
          </div>

          <p className={styles.info}>{parseMardown(info)}</p>

          <div className={styles.pageList}>
            <div className={styles.pageListHeader}>Kursy</div>
            {allStrapiLanding.edges.map(FooterItem)}
          </div>
        </div>

        <div className={styles.bottomContainer}>
          {shouldShowMenuIconLinks && (
            <div className={styles.socialsContainer}>
              {menuIconLinks.map((item) => (
                <a
                  className={styles.menuIconLink}
                  href={item.link}
                  target="_blank"
                  rel="nofollow"
                  key={item.link}
                >
                  <Image className={styles.menuIcon} image={item.iconFooter} />
                </a>
              ))}
            </div>
          )}
        </div>

        {/*<pre>{JSON.stringify(data, null, 2)}</pre>*/}
      </div>
    </div>
  );
};

export default Footer;
